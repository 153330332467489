import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem
} from '@radix-ui/react-select';
import { ChevronRight, Pencil } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const StudentView = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto p-4 max-w-5xl">
      {/* Header */}
      <div className="mb-6">
        <div className="flex items-center gap-2 text-muted-foreground">
          <span>{t('activity.student.students')}</span>
          <ChevronRight className="h-4 w-4" />
          <span>Matias Pozzo</span>
        </div>
      </div>

      <Card className="p-6">
        {/* Personal Data Section */}
        <div className="flex justify-between items-start mb-8">
          <h2 className="text-xl font-semibold">
            {t('activity.student.personalData')}
          </h2>
          <Button asChild variant="outline" size="sm">
            <Link to={'edit'}>
              <Pencil className="h-4 w-4 mr-2" />
              {t('form.edit')}
            </Link>
          </Button>
        </div>

        <div className="grid md:grid-cols-2 gap-8 mb-8">
          <div className="space-y-4">
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.lastName')}:
              </p>
              <p>Pozzo</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.firstName')}:
              </p>
              <p>Matias</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.dni')}:
              </p>
              <p>--------</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.birthDate')}:
              </p>
              <p>20/08/96</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.address')}:
              </p>
              <p>Av. Siempre Viva 123</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.age')}:
              </p>
              <p>25</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.shirtSize')}:
              </p>
              <p>L</p>
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.contacts')}:
              </p>
              {[1, 2].map((index) => (
                <div key={index} className="mt-2">
                  <p>{t('activity.student.contactName')}: -----</p>
                  <p>{t('activity.student.contactRelation')}: -----</p>
                  <p>{t('activity.student.contactDNI')}: -----</p>
                  <p>{t('activity.student.contactPhone')}: -----</p>
                  <p>{t('activity.student.contactEmail')}: -----</p>
                </div>
              ))}
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.medicalCoverage')}:
              </p>
              <p>Swiss Medical</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.waterSkillLevel')}:
              </p>
              <p>Medio</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.vacationPeriod')}:
              </p>
              <p>Sí</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.parentAluarEmployee')}:
              </p>
              <p>Sí</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.clubMember')}:
              </p>
              <p>Sí</p>
            </div>
          </div>
        </div>

        {/* Authorized Pickup Section */}
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            {t('activity.student.authorizedPickup')}
          </h2>
          <div className="space-y-2">
            <p>{t('activity.student.authorizedName')}: -----</p>
            <p>{t('activity.student.authorizedDNI')}: -----</p>
            <p>{t('activity.student.authorizedPhone')}: -----</p>
          </div>
        </div>

        {/* Medical History Section */}
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            {t('activity.student.backgroundAndTreatments')}
          </h2>
          <div className="space-y-2">
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.periodicTreatment')}:
              </p>
              <p>Sí</p>
              <p>{t('activity.student.periodicTreatmentDetails')}: -----</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.recentHospitalization')}:
              </p>
              <p>Sí</p>
              <p>{t('activity.student.hospitalizationReason')}: -----</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.allergy')}:
              </p>
              <p>Sí</p>
              <p>{t('activity.student.allergyDetails')}: Polenta</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.allergyTreatment')}:
              </p>
              <p>Sí</p>
              <p>{t('activity.student.allergyTreatmentDetails')}: -----</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.medicalTreatment')}:
              </p>
              <p>Sí</p>
              <p>{t('activity.student.medicalTreatmentDetails')}: -----</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.physicalLimitation')}:
              </p>
              <p>Sí</p>
              <p>{t('activity.student.physicalLimitationDetails')}: -----</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                {t('activity.student.otherHealthIssues')}:
              </p>
              <p>Sí</p>
              <p>{t('activity.student.otherHealthIssuesDetails')}: -----</p>
            </div>
          </div>
        </div>

        {/* Payments Section */}
        <div className="mb-8">
          <h2 className="text-xl font-semibold text-red-500 mb-4">
            {t('activity.student.pendingPayments')}
          </h2>
          <div className="space-y-2">
            <p>{t('activity.student.totalPaid')}: $10</p>
            <p>{t('activity.student.pendingAmount')} $12312</p>
          </div>
        </div>

        {/* Attendance Section */}
        <div>
          <h2 className="text-xl font-semibold mb-4">
            {t('activity.student.attendance')}
          </h2>
          <div className="space-y-4">
            <Select defaultValue="colonia-2025">
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Seleccionar período" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="colonia-2025">Colonia 2025</SelectItem>
              </SelectContent>
            </Select>

            <div className="grid grid-cols-6 md:grid-cols-12 gap-2">
              {Array.from({ length: 12 }).map((_, i) => (
                <div key={i} className="text-center">
                  <p className="text-sm text-muted-foreground mb-1">11/10</p>
                  <div className="h-6 w-6 border rounded-sm mx-auto flex items-center justify-center">
                    {i % 2 === 0 && <span className="text-primary">✓</span>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default StudentView;
