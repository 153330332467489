import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';

import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import { studentData } from './mock-data';
import { Link } from 'react-router-dom';
import {
  ArrowRight,
  Anchor,
  Check,
  Stethoscope,
  CircleSlash2,
  UserX
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useState } from 'react';
import {
  StatusType,
  StudentStatusBadge
} from '../../badges/StudentStatusBadge';

export type Student = {
  estado: string;
  alumno: string;
  presente: boolean;
  contacto: string;
  fechaNacimiento: Date;
  observaciones: string;
};

const columnHelper = createColumnHelper<Student>();

const getTodayDateFormatted = format(new Date(), 'dd/MM');

const StudentTables: React.FC = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date | undefined>(new Date());

  const columns = [
    columnHelper.accessor('estado', {
      cell: (info) => (
        <StudentStatusBadge status={info.getValue() as StatusType} />
      ),
      header: t('activity.table.state'),
      footer: (props) => props.column.id
    }),
    columnHelper.accessor('alumno', {
      cell: (info) => info.getValue(),
      header: t('activity.table.student'),
      footer: (props) => props.column.id
    }),
    columnHelper.accessor('presente', {
      header: () => (
        <div className="flex items-center gap-2">
          <span>{`${t('activity.table.attend')} (${getTodayDateFormatted})`}</span>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  'w-[40px] p-0 hover:bg-muted',
                  date && 'text-primary'
                )}
              >
                <CalendarIcon className="h-4 w-4" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={date}
                onSelect={setDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>
      ),
      cell: (info) => (
        <div>
          <Checkbox />
        </div>
      ),
      footer: (props) => props.column.id,
      enableColumnFilter: true
    }),
    columnHelper.accessor('contacto', {
      cell: (info) => info.getValue(),
      header: t('activity.table.contact'),
      footer: (props) => props.column.id
    }),
    columnHelper.accessor('fechaNacimiento', {
      cell: (info) => info.getValue().toLocaleDateString(),
      header: t('activity.table.bornDate'),
      footer: (props) => props.column.id
    }),
    columnHelper.accessor('observaciones', {
      cell: (info) => info.getValue(),
      header: t('activity.table.observation'),
      footer: (props) => props.column.id
    }),
    columnHelper.display({
      id: 'acciones',
      header: '',
      cell: ({ row }) => (
        <Link to={`students/${row.original.alumno}`} className="w-full">
          <ArrowRight />
        </Link>
      )
    })
  ];

  const table = useReactTable({
    data: studentData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableMultiSort: false,
    manualSorting: true,
    sortDescFirst: true,
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      columnVisibility: {
        id: false
      }
    }
  });

  return <TanStackBasicTableTableComponent table={table} isLoading={false} />;
};

export default StudentTables;
