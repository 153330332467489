import api from '@/services/api';
import {
  keepPreviousData,
  skipToken,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { getMonth } from 'date-fns';
import { useEffect } from 'react';

const getServiceAvailabilityQueryOptions = ({
  // TODO: Ver por que no se puede poner una QueryFn que tenga un skiptoken
  serviceId,
  participants,
  month,
  year
}: {
  serviceId: string | undefined;
  participants: number | undefined;
  month: number;
  year: number;
}) => {
  return {
    queryKey: [
      'servicesAvailability',
      { serviceId, participants, month, year }
    ],
    staleTime: 10 * 10000
  };
};

const useServicesAvailability = ({
  serviceId,
  participants,
  month,
  year
}: {
  serviceId: string | undefined;
  participants: number | undefined;
  month: number;
  year: number;
}) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    // Calculate next month and year
    let nextMonth = month + 1;
    let nextYear = year;
    if (nextMonth > 12) {
      nextMonth = 1;
      nextYear = year + 1;
    }

    // Prefetch next month's availability
    queryClient.prefetchQuery({
      ...getServiceAvailabilityQueryOptions({
        serviceId,
        participants,
        month: nextMonth,
        year: nextYear
      }),
      queryFn:
        serviceId && participants
          ? () =>
              api.services.getAvailability({
                serviceId,
                participants,
                month: nextMonth,
                year: nextYear
              })
          : skipToken
    });

    // Calculate previous month and year
    let prevMonth = month - 1;
    let prevYear = year;
    if (prevMonth < 1) {
      prevMonth = 12;
      prevYear = year - 1;
    }

    // Prefetch previous month's availability
    queryClient.prefetchQuery({
      ...getServiceAvailabilityQueryOptions({
        serviceId,
        participants,
        month: prevMonth,
        year: prevYear
      }),
      queryFn:
        serviceId && participants
          ? () =>
              api.services.getAvailability({
                serviceId,
                participants,
                month: prevMonth,
                year: prevYear
              })
          : skipToken
    });
  }, [serviceId, participants, month, year]);


  return useQuery({
    ...getServiceAvailabilityQueryOptions({
      serviceId,
      participants,
      month,
      year
    }),
    queryFn:
      serviceId && participants
        ? () =>
            api.services.getAvailability({
              serviceId,
              participants,
              month,
              year
            })
        : skipToken,
    placeholderData: keepPreviousData
  });
};
export default useServicesAvailability;
