import type { Student } from './StudentsTable';

export const studentData: Student[] = [
  {
    estado: 'active',
    alumno: 'María González Pérez',
    presente: true,
    contacto: '555-1234 / maria.gonzalez@email.com',
    fechaNacimiento: new Date(2010, 5, 15),
    observaciones: 'Alumna destacada en matemáticas'
  },
  {
    estado: 'vacation',
    alumno: 'Juan Carlos Rodríguez',
    presente: false,
    contacto: '555-5678 / juan.rodriguez@email.com',
    fechaNacimiento: new Date(2009, 2, 22),
    observaciones: 'Requiere seguimiento académico'
  },
  {
    estado: 'sick',
    alumno: 'Dave Mustaine',
    presente: false,
    contacto: '555-5678 / juan.rodriguez@email.com',
    fechaNacimiento: new Date(2009, 2, 22),
    observaciones: 'Requiere seguimiento académico'
  },
  {
    estado: 'active',
    alumno: 'Ana Laura Martínez',
    presente: true,
    contacto: '555-9012 / ana.martinez@email.com',
    fechaNacimiento: new Date(2011, 8, 3),
    observaciones: 'Participante activa en actividades extracurriculares'
  },
  {
    estado: 'inactive',
    alumno: 'Carlos Sánchez López',
    presente: false,
    contacto: '555-3456 / carlos.sanchez@email.com',
    fechaNacimiento: new Date(2008, 11, 10),
    observaciones: 'Suspensión temporal por indisciplina'
  },
  {
    estado: 'recurring_absent',
    alumno: 'Elena Ruiz Fernández',
    presente: true,
    contacto: '555-7890 / elena.ruiz@email.com',
    fechaNacimiento: new Date(2010, 4, 18),
    observaciones: 'Buen desempeño en ciencias'
  },
  {
    estado: 'inactive',
    alumno: 'Elena Ruiz Fernández 2',
    presente: true,
    contacto: '555-78920 / elena.ruiz2@email.com',
    fechaNacimiento: new Date(2010, 4, 18),
    observaciones: 'Buen desempeño en ciencias'
  }
];
