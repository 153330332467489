import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { zodResolver } from '@hookform/resolvers/zod';

import { ChevronRight, Pencil, Plus } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const formSchema = z.object({
  dni: z.string().min(1, 'DNI es requerido'),
  fechaNacimiento: z.string().min(1, 'Fecha de nacimiento es requerida'),
  direccion: z.string().min(1, 'Dirección es requerida'),
  celular: z.string(),
  coberturaMedica: z.string(),
  nivelAgua: z.string(),
  contactoPadreNombre: z.string(),
  contactoPadreTelefono: z.string(),
  contactoTioNombre: z.string(),
  contactoTioTelefono: z.string(),
  alergia: z.string(),
  limitacionFisica: z.string(),
  tratamientoMedico: z.string(),
  otrosProblemasSalud: z.string()
});

const StudentViewEdit = () => {
  const { t } = useTranslation();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      dni: '',
      fechaNacimiento: '',
      direccion: '',
      celular: '',
      coberturaMedica: '',
      nivelAgua: '',
      contactoPadreNombre: '',
      contactoPadreTelefono: '',
      contactoTioNombre: '',
      contactoTioTelefono: '',
      alergia: '',
      limitacionFisica: '',
      tratamientoMedico: ''
    }
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    console.log(values);
  }

  return (
    <div className="container mx-auto p-4 max-w-5xl">
      {/* Header */}
      <div className="mb-6">
        <div className="flex items-center gap-2 text-muted-foreground">
          <span>{t('activity.student.students')}</span>
          <ChevronRight className="h-4 w-4" />
          <span>Matias Pozzo</span>
          <ChevronRight className="h-4 w-4" />
          <span>{t('form.edit')}</span>
        </div>
      </div>

      <Card className="p-6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            {/* Personal Data Section */}
            <div>
              <h2 className="text-xl font-semibold mb-6">
                {t('activity.student.personalData')}
              </h2>
              <div className="grid md:grid-cols-2 gap-6">
                <FormField
                  control={form.control}
                  name="dni"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('activity.student.dni')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="fechaNacimiento"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel> {t('activity.student.birthDate')}</FormLabel>
                      <FormControl>
                        <Input {...field} type="date" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="direccion"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('activity.student.address')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="celular"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('activity.student.phone')}</FormLabel>
                      <FormControl>
                        <Input {...field} type="tel" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="coberturaMedica"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('activity.student.medicalCoverage')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="nivelAgua"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('activity.student.waterSkillLevel')}
                      </FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Seleccionar nivel" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="bajo">Bajo</SelectItem>
                          <SelectItem value="medio">Medio</SelectItem>
                          <SelectItem value="alto">Alto</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* Contacts Section */}
              <div className="mt-6 space-y-6">
                <div className="grid md:grid-cols-2 gap-6">
                  <FormField
                    control={form.control}
                    name="contactoPadreNombre"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Contacto Padre</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Nombre" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="contactoPadreTelefono"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Contacto número (Padre)</FormLabel>
                        <FormControl>
                          <Input {...field} type="tel" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid md:grid-cols-2 gap-6">
                  <FormField
                    control={form.control}
                    name="contactoTioNombre"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Contacto Tío</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Nombre" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="contactoTioTelefono"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Contacto número (Tío)</FormLabel>
                        <FormControl>
                          <Input {...field} type="tel" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <Button type="button" variant="outline" className="w-full">
                  <Plus className="h-4 w-4 mr-2" />
                  Agregar otro contacto
                </Button>
              </div>
            </div>

            {/* Medical History Section */}
            <div>
              <h2 className="text-xl font-semibold mb-6">
                {t('activity.student.backgroundAndTreatments')}
              </h2>
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <Label>Recibe tratamiento médico?</Label>
                  <Select defaultValue="colonia-2025">
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Tratamiento" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="true">Si</SelectItem>
                      <SelectItem value="false">No</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <FormField
                  control={form.control}
                  name="alergia"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('activity.student.allergy')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="limitacionFisica"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('activity.student.physicalLimitation')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div>
                  <Label>¿Otros problemas de salud?</Label>
                  <Select defaultValue="colonia-2025">
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Tratamiento" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="true">Si</SelectItem>
                      <SelectItem value="false">No</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <FormField
                  control={form.control}
                  name="otrosProblemasSalud"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('activity.student.physicalLimitation')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="mt-6">
                <FormField
                  control={form.control}
                  name="tratamientoMedico"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('activity.student.medicalTreatment')}:
                      </FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            {/* Attendance Section */}
            <div>
              <h2 className="text-xl font-semibold mb-6">
                {t('activity.student.attendance')}
              </h2>
              <div className="space-y-4">
                <Select defaultValue="colonia-2025">
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Seleccionar período" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="colonia-2025">Colonia 2025</SelectItem>
                  </SelectContent>
                </Select>

                <div className="grid grid-cols-6 md:grid-cols-12 gap-2">
                  {Array.from({ length: 12 }).map((_, i) => (
                    <div key={i} className="text-center">
                      <p className="text-sm text-muted-foreground mb-1">
                        11/10
                      </p>
                      <Button
                        type="button"
                        variant="outline"
                        size="sm"
                        className="h-6 w-6 p-0"
                      >
                        {i % 2 === 0 && '✓'}
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-4">
              <Button type="button" variant="outline">
                Cancelar
              </Button>
              <Button type="submit">Guardar cambios</Button>
            </div>
          </form>
        </Form>
      </Card>
    </div>
  );
};

export default StudentViewEdit;
