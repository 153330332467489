import StudentTables from '@/components/common/tables/students-table/StudentsTable';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';

const Activity = () => {
  const { t } = useTranslation();
  return (
    <section className="w-full">
      <div className="space-y-2">
        <h1 className="text-2xl font-bold">{t('activity.title')}</h1>
        <h2 className="text-xl ">
          {t('activity.season')}: 2025 (
          <span
            className="text-sky-500 cursor-pointer"
            onClick={() => alert('SARASA')}
          >
            {t('activity.change')}
          </span>
          )
        </h2>
      </div>

      <div className="w-full flex justify-between items-center mt-8">
        <Button variant="outline">{t('activity.group')} 6-7</Button>
        <Button>{t('activity.download')}</Button>
      </div>

      <div className="mt-4">
        <StudentTables />
      </div>
    </section>
  );
};

export default Activity;
