import { Badge } from '@/components/ui/badge';
import {
  Activity,
  ThermometerSnowflake,
  Palmtree,
  XCircle,
  AlertTriangle
} from 'lucide-react';

export type StatusType =
  | 'active'
  | 'sick'
  | 'vacation'
  | 'inactive'
  | 'recurring_absent';

const statusConfig = {
  active: {
    icon: Activity,
    label: 'Activo',
    variant: 'default' as const
  },
  sick: {
    icon: ThermometerSnowflake,
    label: 'Enfermo',
    variant: 'destructive' as const
  },
  vacation: {
    icon: Palmtree,
    label: 'Vacaciones',
    variant: 'secondary' as const
  },
  inactive: {
    icon: XCircle,
    label: 'Inactivo',
    variant: 'outline' as const
  },
  recurring_absent: {
    icon: AlertTriangle,
    label: 'Ausente recurrente',
    variant: 'warning' as const
  }
};

export const StudentStatusBadge = ({ status }: { status: StatusType }) => {
  const config = statusConfig[status];
  const Icon = config.icon;

  return (
    <Badge variant={config.variant} className="gap-1">
      <Icon className="w-3 h-3" />
      {config.label}
    </Badge>
  );
};
