import { getMonth } from 'date-fns';
import { useReducer } from 'react';
interface CalendarState {
  month: number;
  year: number;
}
type CalendarAction =
  | { type: 'NEXT_MONTH' }
  | { type: 'PREVIOUS_MONTH' }
  | { type: 'SET_MONTH'; payload: number }
  | { type: 'SET_YEAR'; payload: number };
const initialState = (initialDate?: Date): CalendarState => {
  const currentMonth = getMonth(new Date()) + 1;
  const initialMonth = initialDate && getMonth(initialDate) + 1;

  if (initialMonth && initialMonth > currentMonth) {
    return {
      month: initialMonth,
      year: new Date().getFullYear()
    };
  }

  return {
    month: currentMonth,
    year: new Date().getFullYear()
  };
};

const reducer = (
  state: CalendarState,
  action: CalendarAction
): CalendarState => {
  switch (action.type) {
    case 'NEXT_MONTH':
      return state.month === 12
        ? { month: 1, year: state.year + 1 }
        : { month: state.month + 1, year: state.year };
    case 'PREVIOUS_MONTH':
      return state.month === 1
        ? { month: 12, year: state.year - 1 }
        : { month: state.month - 1, year: state.year };
    case 'SET_MONTH':
      return { ...state, month: action.payload };
    case 'SET_YEAR':
      return { ...state, year: action.payload };
    default:
      return state;
  }
};
export interface TCalendarPaginateNavigation {
  month: number;
  year: number;
  date: Date;
  goToNextMonth: () => void;
  goToPreviousMonth: () => void;
  setMonth: (newMonth: number) => void;
  setYear: (newYear: number) => void;
}
export default function useCalendarPaginateNavigation(
  initialDate?: Date
): TCalendarPaginateNavigation {
  const [state, dispatch] = useReducer(reducer, initialState(initialDate));
  const { month, year } = state;
  const goToNextMonth = () => dispatch({ type: 'NEXT_MONTH' });
  const goToPreviousMonth = () => dispatch({ type: 'PREVIOUS_MONTH' });
  const setMonth = (newMonth: number) =>
    dispatch({ type: 'SET_MONTH', payload: newMonth });
  const setYear = (newYear: number) =>
    dispatch({ type: 'SET_YEAR', payload: newYear });
  const date = new Date(year, month - 1);
  return { month, year, goToNextMonth, goToPreviousMonth, setMonth, setYear, date };
}
