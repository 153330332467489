import CalendarModal from '@/components/common/modals/calendar-modal/CalendarModal';
import { Button } from '@/components/ui/button';
import { DialogFooter } from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { TimePicker } from '@/components/ui/time-picker';
import reprogrameDateSchema from '@/schemas/reprograme-date';
import {
  getRemainingMonths,
  getTimeZone,
  getTimestampUTC
} from '@/utils/dates';
import {
  SCHEDULE_STATUS,
  findSchedule as getSchedule,
  sortSchedule,
  validateCustomTime
} from '@/utils/schedule';
import { zodResolver } from '@hookform/resolvers/zod';
import { addMonths, format, getMonth, isPast } from 'date-fns';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { ReprogrameDateFormProps } from './reprogrameDateForm.types';

export type TForm = z.input<ReturnType<typeof reprogrameDateSchema>>;

const ReprogrameDateForm: React.FC<ReprogrameDateFormProps> = ({
  availableDates,
  onSave,
  startTime,
  calendarPaginateNavigation,
  organization
}) => {
  const { t } = useTranslation();

  // Sort available dates
  const currentMonth = calendarPaginateNavigation.month;

  // Get the current reservation date in the given timezone
  const currentReservationDate = startTime
    ? getTimeZone(startTime, organization.tz)
    : undefined;

  // Validate that the initial or default Date is NOT a date in the past.
  const isValidCurrentDate =
    currentReservationDate && !isPast(currentReservationDate);

  // get the available dates and sort them
  const schedule = sortSchedule(availableDates);
  const findSchedule = (date: Date) => getSchedule({ date, schedule });
  const isInvalidCustomTime = (date: Date) =>
    validateCustomTime({ date, schedule });
  const hasSchedule = !!schedule.length;

  const defaultDate = () => {
    if (isValidCurrentDate) {
      return currentReservationDate;
    }
    if (hasSchedule) {
      return schedule[0].date;
    }
    return new Date();
  };

  const defaultTime = () => {
    if (isValidCurrentDate) {
      const parsedTime = defaultDate();
      const slots = findSchedule(parsedTime)?.slots;
      const parsedFirstReservationStartTime = format(parsedTime, 'HH:mm');
      return slots?.find((slot) =>
        slot.from.startsWith(parsedFirstReservationStartTime)
      );
    }
  };

  const toMonth = addMonths(new Date(), getRemainingMonths('2024-11-01')); //TODO: cambiar fecha hardcodeada por fecha de cierre de escuelaπ

  const form = useForm<TForm>({
    resolver: zodResolver(reprogrameDateSchema(isInvalidCustomTime)),
    defaultValues: {
      date: defaultDate(),
      time: defaultTime(),
      timeTabs: 'default'
    },
    mode: 'onChange'
  });

  const tabs = form.getValues().timeTabs;

  function onSubmit(values: TForm) {
    let time;
    if (typeof values.time !== 'undefined') {
      time = values.time.from; // Gets the first part of the time range
    }
    const firstReservationStartTime = getTimestampUTC({
      date: values.date,
      time,
      tz: organization.tz
    });
    if (firstReservationStartTime) {
      onSave(firstReservationStartTime);
    }
  }

  const dateFormValue = form.getValues('date');

  // Check if the form values have changed
  const hasChanged = useCallback(() => {
    const { time: timeValue, date } = form.getValues();
    let time;
    if (typeof timeValue !== 'undefined') {
      time = timeValue.from; // Gets the first part of the time range
    }
    if (tabs === 'default') {
      if (time && date) {
        const formDate = getTimestampUTC({ date, time, tz: organization.tz });
        return formDate === startTime;
      }
    }
    return false;
  }, [form.getValues()]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem className="flex flex-col ">
              <FormLabel>{t('tables.common.date')}</FormLabel>
              <CalendarModal
                value={field.value}
                placeholder={t('tables.common.date')}
                onAccept={(value) => {
                  form.reset({
                    ...form.getValues(),
                    date: value,
                    time: undefined
                  });
                }}
                toMonth={toMonth}
                disabled={(date) => {
                  if (!schedule) return true;
                  return !schedule.some(
                    (d) => date.getTime() === d.date.getTime()
                  );
                }}
                onMonthChange={(date) => {
                  const curretMonthPaginated = calendarPaginateNavigation.date;
                  if (curretMonthPaginated.getTime() < date.getTime()) {
                    calendarPaginateNavigation.goToNextMonth();
                  } else {
                    calendarPaginateNavigation.goToPreviousMonth();
                  }
                  form.reset({
                    ...form.getValues(),
                    time: undefined
                  });
                }}
                onOpenChange={(value) => {
                  if (!value) {
                    const currentDate = dateFormValue;
                    if (currentDate) {
                      const currentMonth = getMonth(currentDate) + 1;
                      calendarPaginateNavigation.setMonth(currentMonth);
                    }
                  }
                }}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        {dateFormValue && (
          <Tabs
            value={tabs}
            defaultValue="default"
            onValueChange={(value) => {
              form.reset({
                date: dateFormValue,
                timeTabs: value,
                time: value === 'default' ? defaultTime() : undefined
              });
            }}
          >
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="default">
                {t('common.defaultDates')}
              </TabsTrigger>
              <TabsTrigger value="custom">
                {t('common.customDates')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="default">
              {/* add validation to prevent modify the information when is disnmounting */}
              {tabs === 'default' && (
                <FormField
                  control={form.control}
                  name="time"
                  render={({ field }) => {
                    const slots = dateFormValue
                      ? findSchedule(dateFormValue)?.slots
                      : undefined;
                    const from = field?.value?.from;
                    const to = field?.value?.to;
                    const value = from && to ? `${from}-${to}` : undefined;
                    return (
                      <FormItem>
                        <Select
                          value={value}
                          onValueChange={(slot) => {
                            const findSlot = slots?.find((data) => {
                              const slotValue = `${data.from}-${data.to}`;
                              return slotValue === slot;
                            });
                            field.onChange(findSlot);
                          }}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue
                                placeholder={t('newBooking.timeRange')}
                              />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {slots?.map((slot: any) => {
                              const from = slot.from;
                              const to = slot.to;
                              const value = `${from}-${to}`;
                              const isAvailable =
                                slot.status === SCHEDULE_STATUS.available;
                              if (!isAvailable) {
                                return (
                                  <SelectItem
                                    key={value}
                                    value={value}
                                    disabled
                                  >
                                    {value}
                                    <span className="ml-1 text-red-600">
                                      *{t('newBooking.unAvailable')}
                                    </span>
                                  </SelectItem>
                                );
                              }
                              return (
                                <SelectItem key={value} value={value}>
                                  {value}
                                </SelectItem>
                              );
                            })}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              )}
            </TabsContent>
            <TabsContent value="custom">
              {/* add validation to prevent modify the information when is disnmounting */}
              {tabs === 'custom' && (
                <FormField
                  control={form.control}
                  name="date"
                  render={({ field }) => {
                    return (
                      <div className="flex flex-col place-items-center gap-2 pt-3">
                        <TimePicker
                          date={field.value}
                          setDate={(date) => field.onChange(date)}
                        />
                        <FormMessage />
                      </div>
                    );
                  }}
                />
              )}
            </TabsContent>
          </Tabs>
        )}
        <DialogFooter>
          <Button type="submit" disabled={hasChanged()}>
            {t('common.save')}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

export default ReprogrameDateForm;
